import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO/seo"
import Layout from "../layout/layout"
import ContactUsSections from "../pagesStructures/contactUs/contactUsSections"

const ContactUs = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiContactUs.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="contactUs">
      <SEO title={pageContent.content.title} />
      <ContactUsSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query ContactUs($url: String) {
    strapiContactUs(url: { eq: $url }) {
      content {
        content {
          ContactUsSectionFirstConfig {
            heading
            cards {
              btnLink
              btnText
              imgAlt
              imgSrc
              text
              title
            }
          }
          ContactUsSectionSecondConfig {
            contacts {
              content
              link
              socialNetworksIcons {
                icon
                link
              }
              title
            }
            heading
          }
        }
        language
      }
    }
  }
`
