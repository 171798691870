import React from "react"
import { Link } from "gatsby"
import Section from "../../../components/section/Section"
import salesImg from "../../../assets/images/contact-us/sales-img.svg"
import supportImg from "../../../assets/images/contact-us/support-img.svg"
import otherQueriesImg from "../../../assets/images/contact-us/other-queries-img.svg"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  salesImg: salesImg,
  supportImg: supportImg,
  otherQueriesImg: otherQueriesImg,
}

const ContactUsSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, cards } = data.data.content.ContactUsSectionFirstConfig
  const {
    pageContext: { locale },
  } = data.data

  return (
    <Section className={className}>
      <h2 className="sova-section-child sova-h2">{heading}</h2>
      <div className="sova-section-child-container sova-section-child-container_row sova-section-child_contactUs-0-container">
        {cards.map(({ imgSrc, imgAlt, title, text, btnText, btnLink }: any, key: number) => (
          <div key={key} className="sova-section-column sova-section-child_contactUs-0-card">
            <img
              className="sova-section-child sova-section_contactUs-0-card-child sova-section-child_contactUs-0-img sova-card-image sova-section-img sova-image_medium"
              src={images[imgSrc]}
              alt={imgAlt}
            />
            <p className="sova-section-child sova-section_contactUs-0-card-child sova-p-medium">{title}</p>
            <p className="sova-section-child sova-section_contactUs-0-card-child sova-section_contactUs-0-card-child-text sova-p-tiny">{text}</p>
            {btnLink.includes("mailto") ? (
              <a href={`${btnLink}`}>
                <button className="sova-btn sova-btn_secondary sova-btn_small">{btnText}</button>
              </a>
            ) : (
              <Link to={`${locale + btnLink}`}>
                <button className="sova-btn sova-btn_secondary sova-btn_small">{btnText}</button>
              </Link>
            )}
          </div>
        ))}
      </div>
    </Section>
  )
}

ContactUsSectionFirst.displayName = "ContactUsSectionFirst"

export default ContactUsSectionFirst
