import React from "react"

import ContactUsSectionFirst from "./contactUsSections/ContactUsSectionFirst"
import ContactUsSectionSecond from "./contactUsSections/ContactUsSectionSecond"

const sections = [
  { component: (key: number, className: string, data: any) => <ContactUsSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <ContactUsSectionSecond data={data} key={key} className={className} /> },
]

const ContactUsSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-contactUs sova-section-contactUs_${i}`, data))}</>
}

ContactUsSections.displayName = "ContactUsSections"

export default ContactUsSections
