import React from "react"
import Section from "../../../components/section/Section"

import mediumIcon from "../../../assets/images/contact-us/icons/medium-icon-for-contacts-page-sova.ai.svg"
import gitHubIcon from "../../../assets/images/contact-us/icons/gitHub-icon-for-contacts-page-sova.ai.svg"
import facebookIcon from "../../../assets/images/contact-us/icons/facebook-icon-for-contacts-page-sova.ai.svg"
import youtubeIcon from "../../../assets/images/contact-us/icons/youtube-icon-for-contacts-page-sova.ai.svg"
import twitterIcon from "../../../assets/images/contact-us/icons/twitter-icon-for-contacts-page-sova.ai.svg"
import Img from "../../../assets/images/NTI_logo.png"
import { usePageContext } from "../../../components/pageContext/pageContext"

interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  mediumIcon: mediumIcon,
  gitHubIcon: gitHubIcon,
  facebookIcon: facebookIcon,
  youtubeIcon: youtubeIcon,
  twitterIcon: twitterIcon,
}

interface SocialIcon {
  icon: string
  link?: string
}

interface ContactsTypes {
  title?: string
  content?: any
  link?: string
  socialNetworksIcons: SocialIcon[]
}

const ContactUsSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { heading, contacts } = data.data.content.ContactUsSectionSecondConfig
  const { lang } = usePageContext() as any

  return (
    <Section className={className}>
      <div className="sova-section-column sova-section-container_contactUs-1">
        <p className="sova-section-child sova-p-medium sova-p-medium_centered">{heading}</p>
        {contacts.map(({ title, content, link, socialNetworksIcons }: ContactsTypes, key: number) => (
          <div key={key} className="sova-section-column sova-section-child_contactUs-1">
            <p className="sova-section-child sova-section-child-text sova-section-child_contactUs-1-text sova-p-normal">{title}</p>
            {link ? (
              <a href={link} className="sova-section-child sova-section-child-text sova-section-child_contactUs-1-text sova-p-normal sova-p-normal_blue">
                {content}
              </a>
            ) : (
              content &&
              content.map((contentItem: any, key: number) => (
                <p key={key} className="sova-section-child sova-section-child-text sova-section-child_contactUs-1-text sova-p-normal sova-p-normal_blue">
                  {contentItem}
                </p>
              ))
            )}
            {socialNetworksIcons && (
              <div className="sova-section-child-container sova-section-child-container_row sova-section-child_row_contactUs-1">
                {socialNetworksIcons.map(({ icon, link }, key) => (
                  <a href={link} key={key} className="sova-section-child sova-section-child_contactUs-2-socialIcon">
                    <img src={images[icon]} className="sova-section-img" />
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {lang === "ru" && <img src={Img} />}
    </Section>
  )
}

ContactUsSectionSecond.displayName = "ContactUsSectionSecond"

export default ContactUsSectionSecond
